import { COMPANY_EMAIL } from "../../constants.js";

export const opportunitiesData = {
    header: "Current Opportunities",
    description: "We are always on the lookout for talented people so please email our HR team with a copy of your CV at " + COMPANY_EMAIL +" and we will be in touch.",
    positions: [
        {
            title: "Autocad Draftsman",
            description: "Is hiring freshers for Trainee Telecom Engineer position.->Knowledge in CAD/GIS will be added advantage.for more details: Info@clearstreamdn.com"
        },
        {
            title: "Permit Design Engineer / QC Engineer",
            description: "Clearstream Digital Networks Giving opportunity for Junior Design Engineer / AutoCAD Design Engineer &Junior Associate who will going to work variety of engineering functions related to wireless technology, such as the followings : Preparing Work on Construction Drawings and Zoning Drawings based on the site walk notes, RFDS, Previous markup drawings, Quality Assurance, A&E/Small Cell Designs, Rooftop and Tower Upgrade Drawings, New Site Build Drawings for carriers like Verizon, AT&T, T- Mobile, Dish Wireless, American Tower, Pole Loading Analysis (PLA), Fiber designing etc.for more details: Info@clearstreamdn.com"
        },
        {
            title: "Sr OSP Engineer",
            description: "OSP Quality Control specialist.OCALC Pole Modeling Specialist.Permit Specialists with DOT and municipality experience.AutoCAD, FROGS, GAP, IQGeo experience preferred.for more details: Info@clearstreamdn.com"
        }
    ]
};
