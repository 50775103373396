import React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useNavigate } from "react-router-dom";
import Fade from "@mui/material/Fade";

export default function MenuComp(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const navigate = useNavigate();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        if (props.redirect) {
            navigate(props.redirect);
        }
    };

    const handleMenuItemClick = (redirectTo) => {
        setAnchorEl(null);
        if (redirectTo) {
            navigate(redirectTo);
        }
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <Button
                id="demo-positioned-button"
                aria-controls={open ? "demo-positioned-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
                style={{ color: "#1f1a50", fontWeight: "bold" }}
                size="small"
            >
                {props.menu} {props.menuItems.length > 0 && <KeyboardArrowDownIcon />}
            </Button>
            <Menu
                id="demo-positioned-menu"
                aria-labelledby="demo-positioned-button"
                anchorEl={anchorEl}
                open={open && props.menuItems.length > 0}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 55,
                    horizontal: 0,
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
                TransitionComponent={Fade}
                TransitionProps={{ direction: "down" }}
                variant="menu"
                autoFocus={false}
            >
                {props.menuItems.map((item, index) => (
                    <MenuItem
                        key={index}
                        style={{ color: "#1f1a50" }}
                        onClick={() => handleMenuItemClick(item.redirect)}
                    >
                        {item.menu}
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
}
