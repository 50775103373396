import React, { useState } from "react";
import NavbarLogo from "./NavbarLogo/NavbarLogo.jsx";
import MenuGroup from "../MenuGroup/MenuGroup.jsx";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import "./Navbar.scss";

const Navbar = (props) => {
    const [menuOpen, setMenuOpen] = useState(false);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    return (
        <div className="Navbar">
            <NavbarLogo appName={props.appName} logoSrc={props.logoSrc} tagline={props.tagline} />
            <button style={{zIndex:"777"}} className="Navbar__toggle" onClick={toggleMenu}>
                {menuOpen ? <CloseIcon /> : <MenuIcon />}
            </button>
            <div className={`MenuGroup__container ${menuOpen ? "open" : ""}`}>
                <MenuGroup menus={props.menus} />
            </div>
        </div>
    );
};

export default Navbar;
