export const testimonialsData = {
    header: "Testimonials",
    margin: "60px",
    quotes: [
        {
            quote: "When Clearstream understands the scope of work they’re excellent on execution, delivering high quality work, on budget",
            author: "Patt Kaye",
            title: "OSP General Manager"
        },
        {
            quote: "Clearstream consistently delivers excellent work through emerging technologies and a high performing culture",
            author: "Chris Kramer",
            title: "Senior Permit manager"
        },
        {
            quote: "We are extremely happy with the quality of deliverables Clearstream team highly Knowledgeable and understands every phase of our design and drafting requirements.",
            author: "William James",
            title: "VP Operations"
        },
    ]
};
