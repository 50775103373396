import StudyAndCostBg from "../../assets/StudyAndCostBg.png";
import ExploreAnalysis from "../../assets/ServicesImgs/ExploreAnalysis.svg";
import MarketAnalysis from "../../assets/ServicesImgs/MarketAnalysis.svg";
import MaterialSelection from "../../assets/ServicesImgs/MaterialSelection.svg";
import DesignFitMarket from "../../assets/ServicesImgs/DesignFitMarket.svg";
import WirelessConnections from "../../assets/ServicesImgs/WirelessConnections.svg";
import WaterBodies from "../../assets/ServicesImgs/WaterBodies.svg";

const studyAndCostAnalysisData = {
    padding: "8px 15%",
    pageName: "Feasibility study & Cost Analysis",
    bgImage: StudyAndCostBg,
    MainSection: {
        title: "Feasibility study & Cost Analysis",
        para: "Clearstream can advise customers and create a business case potential market study, cost per Home pass, model take-rate % for potential new build markets or overbuild existing markets.",
    },
    LogoCards: [
        { imgSrc: ExploreAnalysis, name: "Exploratory Analysis" },
        { imgSrc: MarketAnalysis, name: "Visulaizing the Market and Feasibility Analysis" },
        { imgSrc: MaterialSelection, name: "Optimum material selection" },
        { imgSrc: DesignFitMarket, name: "Design criteria to fit market demand" },
        { imgSrc: WirelessConnections, name: "Hybrid wireless and wireline connectivity for rural last mile connections" },
        { imgSrc: WaterBodies, name: "Long lead permits- water bodies, RR, pipelines." },
    ],
};

export default studyAndCostAnalysisData;
