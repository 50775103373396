import PermittingBg from "../../assets/ServicesImgs/PermittingBg.png";
import DotPermits from "../../assets/ServicesImgs/DotPermits.svg";
import Township from "../../assets/ServicesImgs/Township.svg";
import Railway from "../../assets/ServicesImgs/Railway.svg";
import Easement from "../../assets/ServicesImgs/Easement.svg";
import JointUsePole from "../../assets/ServicesImgs/JointUsePole.svg";
import SubmitPermit from "../../assets/ServicesImgs/SubmitPermit.svg";
import Coordination from "../../assets/ServicesImgs/Coordination.svg";

const permittingData = {
    padding: "8px 15%",
    pageName: "Permitting",
    bgImage: PermittingBg,
    ImageGradient: "linear-gradient(180deg,#d8d9dd 0%,rgba(223,226,231,0.8) 40%)",
    MainSection: {
        title: "Permitting",
        para: "Our permitting services team will prepare the documents necessary to complete your project in compliance with local standards and requirements. Our team can quickly and accurately identify jurisdictional requirements and set in motion the process for permitting, so you can start construction on time.",
    },
    LogoCards: [
        { imgSrc: DotPermits, name: "Dot Permits" },
        { imgSrc: Township, name: "Township, County, and municipality permits" },
        { imgSrc: Railway, name: "Railroad and other specialty permits" },
        { imgSrc: Easement, name: "Easement research" },
        { imgSrc: JointUsePole, name: "Joint use Pole applications for power and telecom" },
        { imgSrc: SubmitPermit, name: "Submit permit to permitting authority" },
        { imgSrc: Coordination, name: "Permit Coordination" },
    ],
};

export default permittingData;
