import PoleLoadingAnalysisBg from "../../assets/ServicesImgs/PoleLoadingAnalysisBg.jpg";
import QuickPole from "../../assets/ServicesImgs/QuickPole.svg";
import NescGuidLines from "../../assets/ServicesImgs/NescGuidLines.svg";
import PowerGuidlines from "../../assets/ServicesImgs/PowerGuidlines.svg";
import WeatherReq from "../../assets/ServicesImgs/WeatherReq.svg";

const poleLoadingData = {
    padding: "8px 15%",
    pageName: "Pole Loading Analysis",
    bgImage: PoleLoadingAnalysisBg,
    ImageGradient: "linear-gradient(180deg,#d8d9dd 0%,rgba(223,226,231,0.8) 40%)",
    MainSection: {
        title: "Pole Loading Analysis",
        para: "Pole loading analysis services provide a path to joint use of poles in a regulations environment that is complex and often difficult to navigate. We utilize the most advanced software available to analyze the structural integrity of existing poles to determine their level of reliability in providing service while withstanding their current or foreseen loading demands.",
    },
    LogoCards: [
        { imgSrc: QuickPole, name: "Using various software such as: OCALC, SpidaCalc, QuickPole" },
        { imgSrc: PowerGuidlines, name: "Meet NESC & GO 95 guidelines" },
        { imgSrc: NescGuidLines, name: "Prepare MRE sheets Make ready Engineering" },
        { imgSrc: WeatherReq, name: "Meet fire or extreme weather requirements" },
    ],
};

export default poleLoadingData;
