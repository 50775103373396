import EngAndDesign from "../assets/ServicesImgs/EngAndDesign.png";
import Fielding from "../assets/ServicesImgs/Fielding.png";
import GIS from "../assets/ServicesImgs/GIS.jpg";
import Permitting from "../assets/ServicesImgs/Permitting.png";
import PoleLoadingAnalysis from "../assets/ServicesImgs/PoleLoadingAnalysis.png";
import StudyAndCostAnalysis from "../assets/ServicesImgs/StudyAndCostAnalysis.jpg";
import TrafficPlanControl from "../assets/ServicesImgs/TrafficPlanControl.jpg";
import BIM from "../assets/ServicesImgs/BIM.jpg";
import CivilArchCad from "../assets/ServicesImgs/CivilArchCad.jpg";
import MechanicalCad from "../assets/ServicesImgs/MechanicalCad.png";

export const services = [
    { title: "Telecom Planning & Design", url: EngAndDesign, width: "300px", redirect: "/engineering-design" },
    { title: "Permitting", url: Permitting, width: "300px", redirect: "/permitting" },
    { title: "Traffic Control Plans", url: TrafficPlanControl, width: "300px", redirect: "/traffic-control-plans" },
    { title: "GIS", url: GIS, width: "300px", redirect: "/geospatial" },
    { title: "Pole Loading Analysis", url: PoleLoadingAnalysis, width: "300px", redirect: "/pole-loading" },
    { title: "BIM", url: BIM, width: "300px", redirect: "/bim" },
    { title: "Fielding", url: Fielding, width: "300px", redirect: "/fielding" },
    { title: "Feasibility study & Cost Analysis", url: StudyAndCostAnalysis, width: "300px", redirect: "/feasibility-study-cost-analysis" },
    { title: "Mechanical CAD", url: MechanicalCad, width: "300px", redirect: "/mechanical-cad" },
    { title: "Civil and architectural CAD", url: CivilArchCad, width: "300px", redirect: "/civil-architectural-cad" },
];