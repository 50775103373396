import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import PersonIcon from '@mui/icons-material/Person';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

export const networkData = {
    header: "Our Network",
    cards: [
        { ICON: PeopleAltIcon, info: "50+", desc: "Employees & Consultants" },
        { ICON: PersonIcon, info: "50+", desc: "Serving 50+ Clients" },
        { ICON: TrendingUpIcon, info: "50%", desc: "Growing at a rate of 50%" },
        { ICON: AccessTimeIcon, info: "24-72", desc: "Hours turn around time" },
    ],
    margin: "10vh"
};
