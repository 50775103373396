import React from "react";
import "./NavbarLogo.scss";

const NavbarLogo = ({ logoSrc, appName, tagline }) => {
    return (
        <div className="NavbarLogo">
            <img src={logoSrc} alt="Logo" className="NavbarLogo__image" />
            <div className="NavbarLogo__text">
                <span className="NavbarLogo__appName">{appName}</span>
                {tagline && <span className="NavbarLogo__tagline">{tagline}</span>}
            </div>
        </div>
    );
};

export default NavbarLogo;
