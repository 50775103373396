import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import EmailIcon from "@mui/icons-material/Email";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import { COMPANY_EMAIL } from "../../constants.js";

export const contactInfo = [
    {
        icon: <LocalPhoneIcon />,
        text: "+91 9505795717"
    },
    {
        icon: <EmailIcon />,
        text: "Inquiry: " + COMPANY_EMAIL
    },
    {
        icon: <FmdGoodIcon />,
        text: "#302, PLOT NO. 244 & 245,BRUNDAVANAM,ANJANEYANAGAR BALANAGAR ,HYDERABAD-500018 TELANGANA TEL. NO.:+91 9505795717"
    }
];
