import React from "react";
import "./TeamSection.scss";
import ActionAreaCard from "../../../components/Cards/ActionAreaCard/ActionAreaCard.jsx";
import { teamMembers } from "../../../data/about-us/teamMembers.js";

export default function TeamSection() {
    return (
        <div className="TeamSection">
            <h3 data-aos="flip-right" className="header">Meet the team</h3>
            <div className="cards-container">
                {teamMembers.map((member, index) => (
                    <ActionAreaCard
                        key={index}
                        image={member.image}
                        title={member.title}
                        description={member.description}
                    />
                ))}
            </div>
        </div>
    );
}
