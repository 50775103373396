import React from "react";
import "./MenuGroup.scss";
import MenuComp from "../Menu/MenuComp.jsx";

const MenuGroup = (props) => {
    return (
        <div className="MenuGroup">
            {props.menus.map((menu, idx) => (
                <MenuComp key={idx} menu={menu.menu} redirect={menu.redirect} menuItems={menu.menuItems} />
            ))}
        </div>
    );
};

export default MenuGroup;
