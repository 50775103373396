import BimBg from "../../assets/ServicesImgs/BimBg.png";
import StructuralDesgin from "../../assets/ServicesImgs/StructuralDesgin.png";
import DigitalMapping from "../../assets/ServicesImgs/DigitalMapping.svg";
import ThreeDModeling from "../../assets/ServicesImgs/3DModeling.png";

const bimData = {
    padding: "8px 15%",
    pageName: "BIM (Building Information Modeling)",
    bgImage: BimBg,
    MainSection: {
        title: "BIM (Building Information Modeling)",
        para: "Building Information Modeling (BIM) is a digital representation of the physical and functional characteristics of a building. It provides a comprehensive and collaborative platform for all stakeholders involved in the design, construction, and operation of buildings. Here are some lines on the services provided by BIM:",
    },
    LogoCards: [
        { imgSrc: StructuralDesgin, name: "Structural Design" },
        { imgSrc: DigitalMapping, name: "Digital Cad" },
        { imgSrc: ThreeDModeling, name: "3D Modeling" },
    ],
};

export default bimData;
