import FieldingBg from "../../assets/ServicesImgs/FieldingBg.png";
import Walkout from "../../assets/ServicesImgs/Walkout.svg";
import FieldSurveys from "../../assets/ServicesImgs/FieldSurveys.svg";
import CopperAudits from "../../assets/ServicesImgs/BusinessCellSite.svg";
import PoleProfiling from "../../assets/ServicesImgs/PoleProfiling.svg";
import UnderGroundDesign from "../../assets/ServicesImgs/UnderGroundDesign.svg";
import DigitalMapping from "../../assets/ServicesImgs/DigitalMapping.svg";
import VariousCollectionTools from "../../assets/ServicesImgs/VariousCollectionTools.svg";

const fieldingData = {
    padding: "8px 15%",
    pageName: "Fielding",
    bgImage: FieldingBg,
    MainSection: {
        title: "Fielding",
        para: "Our field engineering services allow us to put boots on the ground to survey, audit, and map various facilities and equipment. Our field engineers use the latest technological tools for data capture, data retrieval and data updates. They can assist your company in providing cost effective and timely solutions to real world problems.",
    },
    LogoCards: [
        { imgSrc: Walkout, name: "New-Build Walkout" },
        { imgSrc: FieldSurveys, name: "Field Surveys" },
        { imgSrc: CopperAudits, name: "Fiber/Copper Audits" },
        { imgSrc: PoleProfiling, name: "Pole Profiling/Pole Auditing" },
        { imgSrc: UnderGroundDesign, name: "Underground Design" },
        { imgSrc: DigitalMapping, name: "Digital Mapping/Fielding" },
        { imgSrc: VariousCollectionTools, name: "Various Collection Tools" },
    ],
};

export default fieldingData;
