import Navbar from "../../components/Navbar/Navbar.jsx";
import { menus } from "../../data";
import AppLogo from "../../assets/AppLogo.png";
import FullWidthBanner from "../../components/FullWidthBanner/FullWidthBanner.jsx";
import CareersBg from "../../assets/CareersBg.jpg";
import CultureSection from "./CultureSection/CultureSection.jsx";
import { APP_NAME } from "../../constants.js";
import Footer from "../Home/Footer/Footer.jsx";

export default function Careers() {
    return (
        <div className="Careers">
            <Navbar appName={APP_NAME} logoSrc={AppLogo} menus={menus} tagline={"Digital Networks"} />
            <FullWidthBanner
                padding={"8px 15%"}
                bgImage={CareersBg}
                pageName={"Careers"}
            />
            <CultureSection />
            <Footer />
        </div>
    );
}
