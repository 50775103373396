import React from "react";
import "./VisionSection.scss";
import VisionBg from "../../../assets/VisionBg.png";
import CrisisAlertIcon from "@mui/icons-material/CrisisAlert";

export default function VisionSection() {
    return (
        <div
            className="VisionSection"
            style={{
                backgroundImage: `url(${VisionBg})`,
            }}
        >
            <CrisisAlertIcon className="icon" />
            <div className="text-container">
                <h3 className="header">VISION</h3>
                <p className="para">Be the global choice for Engineering design and system integration</p>
            </div>
        </div>
    );
}
