import React from "react";
import InfoCard from "../../../components/Cards/InfoCard/InfoCard.jsx";
import MarginDivider from "../../../components/MarginDivider.jsx";
import { networkData } from "../../../data/home/networkData.js";
import "./NetworkSection.scss";

export default function NetworkSection() {
    return (
        <div className="NetworkSection">
            <h3 data-aos="flip-right" className="header">{networkData.header}</h3>
            <MarginDivider margin={networkData.margin} />

            <div className="Container">
                {networkData.cards.map((card, index) => (
                    <InfoCard 
                        key={index}
                        ICON={card.ICON}
                        info={card.info}
                        desc={card.desc}
                    />
                ))}
            </div>
        </div>
    );
}
