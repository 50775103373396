import React from "react";
import Navbar from "../../components/Navbar/Navbar.jsx";
import AppLogo from "../../assets/AppLogo.png";
import MarginDivider from "../../components/MarginDivider.jsx";
import IntroSection from "./IntroSection/IntroSection.jsx";
import ExpertiseSection from "./ExpertiseSection/ExpertiseSection.jsx";
import ServicesSection from "./ServicesSection/ServicesSection.jsx";
import ClientSection from "./ClientSection/ClientSection.jsx";
import NetworkSection from "./NetworkSection/NetworkSection.jsx";
import Testimonials from "./Testimonials/Testimonials.jsx";
import IndustriesSection from "./IndustriesSection/IndustriesSection.jsx";
import Collaborate from "./Collaborate/Collaborate.jsx";
import Footer from "./Footer/Footer.jsx";
import { APP_NAME } from "../../constants.js";
import { menus, services } from "../../data";
import { introData } from "../../data/home/introData.js";

const Home = () => {

    return (
        <div>
            <Navbar appName={APP_NAME} logoSrc={AppLogo} menus={menus} tagline={"Digital Networks"} />
            <MarginDivider margin={"10vh"} />
            <IntroSection />
            <div id={introData.buttonTarget}></div>
            <MarginDivider margin={"0vh"} />
            <ExpertiseSection />
            <MarginDivider margin={"10vh"} />
            <ServicesSection services={services} />
            <MarginDivider margin={"25vh"} />
            <ClientSection />
            <MarginDivider margin={"12vh"} />
            <NetworkSection />
            <MarginDivider margin={"12vh"} />
            <Testimonials />
            <IndustriesSection />
            <Collaborate />
            <MarginDivider margin={"15vh"} />
            <Footer />
        </div>
    );
};

export default Home;
