import React from "react";
import "./Footer.scss";
import AppLogo from "../../../assets/AppLogo.png";
import { APP_NAME } from "../../../constants";
import NavbarLogo from "../../../components/Navbar/NavbarLogo/NavbarLogo";

export default function Footer() {
    return (
        <div className="Footer">
            <div style={{display:"flex"}}>
                <div>
                    <NavbarLogo logoSrc={AppLogo} appName={APP_NAME} tagline={"Digital Networks"} />
                </div>
            </div>
            <div className="Footer-text">{APP_NAME} © 2024, All Rights Reserved.</div>
        </div>
    );
}
