import React from "react";
import "./Testimonials.scss";
import TestimonialsBg from "../../../assets/Testimonials/TestimonialsBg.png";
import MarginDivider from "../../../components/MarginDivider.jsx";
import AutoScroller from "../../../components/AutoScroller/AutoScroller.jsx";
import QuotationCard from "../../../components/Cards/QuotationCard/QuotationCard.jsx";
import { testimonialsData } from "../../../data/home/testimonialsData.js";

export default function Testimonials() {
    return (
        <div
            className="Testimonials"
            style={{
                backgroundImage: `url(${TestimonialsBg})`,
            }}
        >
            <h3 data-aos="flip-right" className="header">
                {testimonialsData.header}
            </h3>
            <MarginDivider margin={testimonialsData.margin} />
            <AutoScroller speed={20} gap={5}>
                {testimonialsData.quotes.map((quote, index) => (
                    <QuotationCard key={index} quote={quote.quote} author={quote.author} title={quote.title} />
                ))}
            </AutoScroller>
        </div>
    );
}
