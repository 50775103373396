import ComplexButton from "../../../components/ComplexButton/ComplexButton.jsx";
import "./ServicesSection.scss";

export default function ServicesSection(props) {
    return (
        <div  className="ServicesSection">
            {props.services.map((service, idx) => {
                return (
                    <ComplexButton
                        image={service}
                    />
                );
            })}
        </div>
    );
}
