import "./IndustriesSection.scss";
import Electrical from "../../../assets/IndustriesLogos/Electrical.svg";
import Telecom from "../../../assets/IndustriesLogos/Telecom.svg";
import Utilities from "../../../assets/IndustriesLogos/Utilities.svg";
import CivilServices from "../../../assets/IndustriesLogos/CivilServices.png";
import LogoCard from "../../../components/Cards/LogoCard/LogoCard.jsx";
import MarginDivider from "../../../components/MarginDivider.jsx";

export default function IndustriesSection() {

    return (
        <div className="IndustriesSection">
            <h3 data-aos="flip-right" className="header">Industries Served</h3> 
            <MarginDivider margin={"10vh"} />
            <div className="Container">
                <LogoCard imgSrc={Electrical} name={"Electrical Power"} />
                <LogoCard imgSrc={Telecom} name={"Telecom"} />
                <LogoCard imgSrc={Utilities} name={"Utilities"} />
                <LogoCard imgSrc={CivilServices} name={"Civil"} />
            </div>
            <MarginDivider margin={"5vh"} />
        </div>
    )

}