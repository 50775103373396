import MarginDivider from "../../../components/MarginDivider.jsx";
import AutoScroller from "../../../components/AutoScroller/AutoScroller.jsx";
import AtAndT from "../../../assets/ClientLogos/AT&T.jpg";
import comcast from "../../../assets/ClientLogos/comcast.jpg";
import bentley from "../../../assets/ClientLogos/frntr.jpg";
import charter from "../../../assets/ClientLogos/chartr.jpg";
import verizon from "../../../assets/ClientLogos/verizon.jpg";
import TestimonialsBg from "../../../assets/Testimonials/TestimonialsBg.png";
import "./ClientSection.scss";

export default function ClientSection() {
    return (
        <div className="ClientSection" style={{
            backgroundImage: `url(${TestimonialsBg})`,
        }}>
        <h3 data-aos="flip-right" className="header">Our Clients</h3> 
        <MarginDivider margin={"60px"} />
        <AutoScroller >
            <img src={bentley} alt="Logo" className="Client__image" />
            <img src={AtAndT} alt="Logo" className="Client__image" />
            <img src={charter} alt="Logo" className="Client__image" />
            <img src={comcast} alt="Logo" className="Client__image" />
            <img src={verizon} alt="Logo" className="Client__image" />
        </AutoScroller>
    </div>
    );
}
