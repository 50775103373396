import CivilAndArchCadBg from "../../assets/ServicesImgs/CivilAndArchCadBg.jpg";
import StructuralDesgin from "../../assets/ServicesImgs/StructuralDesgin.png";
import ThreeDModeling from "../../assets/ServicesImgs/3DModeling.png";
import Drafting from "../../assets/ServicesImgs/Drafting.png";
import ThreeDRendering from "../../assets/ServicesImgs/ThreeDRendering.png";
import ThreeDAnimation from "../../assets/ServicesImgs/ThreeDAnimation.png";

const civilAndArchCadData = {
    padding: "8px 15%",
    pageName: "Civil and Architectural CAD",
    bgImage: CivilAndArchCadBg,
    MainSection: {
        title: "Civil and Architectural CAD",
        para: "Clearstream is dedicated to providing tailored solutions to meet the specific needs of our customers. Our CAD Services cover a wide range of areas, including detailed designs, drawings, 3D modeling, rendering, and animations. We use the latest software and cost-effective techniques to ensure maximum productivity in construction, structural, architectural, and interior design projects.",
    },
    LogoCards: [
        { imgSrc: StructuralDesgin, name: "Structural Design" },
        { imgSrc: Drafting, name: "2D Drafting" },
        { imgSrc: ThreeDModeling, name: "3D Modeling" },
        { imgSrc: ThreeDRendering, name: "3D Rendering" },
        { imgSrc: ThreeDAnimation, name: "3D Animations" },
    ],
};

export default civilAndArchCadData;
