import React from "react";
import styled from "styled-components";

const BannerContainer = styled.div`
    width: 100%;
    height: 600px;
    background-image: url(${(props) => props.bgImage});
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: white;
    position: relative;

    @media screen and (max-width: 768px) {
        height: 300px;
    }

    
    @media screen and (max-width: 468px) {
        height: 250px;
    }
`;

const BannerOverlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.4); 
`;

const BannerText = styled.h1`
    position: relative;
    font-size: 2.5rem;
    margin: 0;
    z-index: 1;
    padding: ${(props) => props.padding || '0'};

    @media screen and (max-width: 1600px) {
        padding: 100px 8%;
    }

    @media screen and (max-width: 768px) {
        font-size: 1.5rem;
        padding: 50px 8%;
    }

    @media screen and (max-width: 480px) {
        font-size: 1.2rem;
        padding: 30px 8%;
    }
`;

const FullWidthBanner = ({ bgImage, pageName, padding }) => {
    return (
        <BannerContainer bgImage={bgImage}>
            <BannerOverlay />
            <BannerText padding={padding}>{pageName}</BannerText>
        </BannerContainer>
    );
};

export default FullWidthBanner;
