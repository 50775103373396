import React from "react";
import "./CultureSection.scss";
import CultureCard from "../../../components/Cards/CultureCard/CultureCard.jsx";
import Opportunities from "./Opportunities/Opportunities.jsx";
import { cultureData } from "../../../data/careers/cultureData.js";

export default function CultureSection() {
    return (
        <div className="CultureSection">
            <h3 className="header">Culture</h3>
            <div className="cardContainer">
                {cultureData.cards.map((card, index) => (
                    <CultureCard key={index} icon={card.icon} title={card.title} description={card.description} />
                ))}
            </div>
            <Opportunities />
        </div>
    );
}
