import React from "react";
import "./InfoCard.scss";

export default function InfoCard({ ICON, info, desc }) {
    return (
        <div data-aos="flip-down" className="InfoCard">
            <div className="InfoCard-header">
                <ICON className="InfoCard-icon" />
                <div className="InfoCard-info">{info}</div>
            </div>
            <div className="InfoCard-details">
                <div className="et_pb_text_inner">
                    <p>{desc}</p>
                </div>
            </div>
        </div>
    );
}
