import React from "react";
import "./Opportunities.scss";
import AccordionComp from "../../../../components/AccordionComp/AccordionComp.jsx";
import { opportunitiesData } from "../../../../data/careers/opportunitiesData.js";

export default function Opportunities() {
    return (
        <div className="Opportunities">
            <h3 className="header_op">{opportunitiesData.header}</h3>
            <p className="para_op">
                {opportunitiesData.description}
            </p>
            <br /> <br />
            <div className="accordionContainer">
                {opportunitiesData.positions.map((position, index) => (
                    <AccordionComp
                        key={index}
                        Title={<h3 className="header_acc">{position.title}</h3>}
                        Description={<p className="para_acc">{position.description}</p>}
                    />
                ))}
            </div>
        </div>
    );
}
