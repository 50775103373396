import HowToRegIcon from "@mui/icons-material/HowToReg";
import PsychologyIcon from "@mui/icons-material/Psychology";
import GppGoodIcon from "@mui/icons-material/GppGood";
import Diversity1Icon from "@mui/icons-material/Diversity1";

export const cultureData = {
    header: "Culture",
    cards: [
        {
            icon: HowToRegIcon,
            title: "Strive for Excellence",
            description: "Excellence is a journey from being ordinary to extraordinary. When you strive for excellence, you experience longevity in your achievements.",
        },
        {
            icon: PsychologyIcon,
            title: "Progressive",
            description: "Our employees bring a positive, self-assured attitude to their jobs and initiatives.",
        },
        {
            icon: GppGoodIcon,
            title: "Dependability",
            description: "Time management – Communication – Collaboration – Adaptability – Determination.",
        },
        {
            icon: Diversity1Icon,
            title: "Diversity",
            description: "Clearstream is committed to cultivating a diverse workplace, with employees spread across two continents.",
        },
    ],
};
