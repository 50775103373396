import React, { useState } from "react";
import styled, { keyframes, css } from "styled-components";

const scroll = keyframes`
  0% { transform: translateX(0); }
  100% { transform: translateX(-100%); }
`;

const CarouselContainer = styled.div`
    overflow: hidden;
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    scrollbar-width: none;
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
        display: none;
    }
`;

const CarouselInner = styled.div`
    display: flex;
    width: ${({ itemCount }) => itemCount * 100}%; /* Set width based on item count */
    ${({ speed }) =>
        css`
            animation: ${scroll} ${speed}s linear infinite;
        `}
    ${({ pause }) =>
        pause &&
        css`
            animation-play-state: paused;
        `}
`;

const CarouselItem = styled.div`
    flex: 0 0 ${({ itemCount }) => 100 / itemCount}%; /* Adjust to fit items and handle responsiveness */
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 ${({ gap }) => gap}px;
    box-sizing: border-box;
`;

const AutoScroller = ({ children, gap = 25, speed = 20 }) => {
    const [isPaused, setIsPaused] = useState(false);
    const itemCount = React.Children.count(children);

    const handleMouseEnter = () => {
        setIsPaused(true);
    };

    const handleMouseLeave = () => {
        setIsPaused(false);
    };

    return (
        <CarouselContainer onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            <CarouselInner pause={isPaused} speed={speed} itemCount={itemCount}>
                {React.Children.map(children, (child, index) => (
                    <CarouselItem key={index} gap={gap} itemCount={itemCount}>
                        {child}
                    </CarouselItem>
                ))}
                {React.Children.map(children, (child, index) => (
                    <CarouselItem key={`duplicate-${index}`} gap={gap} itemCount={itemCount}>
                        {child}
                    </CarouselItem>
                ))}
            </CarouselInner>
        </CarouselContainer>
    );
};

export default AutoScroller;
