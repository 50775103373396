import React from "react";
import "./JourneySection.scss";
import InfoCard from "../../../components/Cards/InfoCard/InfoCard.jsx";
import TestimonialsBg from "../../../assets/Testimonials/TestimonialsBg.png";
import { journeyData } from "../../../data/about-us/journeyData.js";

export default function JourneySection() {
    return (
        <div
            className="JourneySection"
            style={{
                backgroundImage: `url(${TestimonialsBg})`,
            }}
        >
            <div className="JourneySection-overlay">
                <h3 className="header_j">{journeyData.header}</h3>
                <div className="content-container">
                    <div data-aos="fade-right" className="journey-details">
                        <h4>{journeyData.aboutUs.title}</h4>
                        <p>{journeyData.aboutUs.description}</p>
                        <ul className="bullet_points">
                            {journeyData.aboutUs.services.map((service, index) => (
                                <li key={index}>
                                    <service.icon /> {service.text}
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div data-aos="fade-right" className="journey-details">
                        <h4>{journeyData.strengths.title}</h4>
                        <p>{journeyData.strengths.description}</p>
                        <ul className="bullet_points">
                            {journeyData.strengths.points.map((point, index) => (
                                <li key={index}>
                                    <point.icon /> {point.text}
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className="info-cards">
                        {journeyData.infoCards.map((card, index) => (
                            <InfoCard key={index} ICON={card.ICON} info={card.info} desc={card.desc} />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}
