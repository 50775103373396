import React, { useState } from "react";
import "./Collaborate.scss";
import CollaborateBg from "../../../assets/Collaborate/CollaborateBgV2.jpg";
import { collaborateData } from "../../../data/home/collaborateData.js";
import { COMPANY_EMAIL } from "../../../constants.js";

export default function Collaborate() {
    const [formData, setFormData] = useState({ name: "", email: "" });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const subject = encodeURIComponent('New Collaboration Request');
        const body = encodeURIComponent(
            `Hello,\n\n` +
            `You have received a new collaboration request through the website.\n\n` +
            `Details:\n` +
            `----------------------------------\n` +
            `Name: ${formData.name}\n` +
            `Email: ${formData.email}\n` +
            `----------------------------------\n\n` +
            `Please get in touch with the requester as soon as possible.\n\n` +
            `Thank you,\n` +
            `Your Website Team`
        );
        const mailtoLink = `mailto:${COMPANY_EMAIL}?subject=${subject}&body=${body}`;
        window.location.href = mailtoLink;
    };

    return (
        <div
            className="Collaborate"
            style={{
                backgroundImage: `url(${CollaborateBg})`,
            }}
        >
            <div className="Container">
                <div data-aos="fade-right" className="About">
                    <h2>{collaborateData.header}</h2>
                    <p>{collaborateData.description}</p>
                </div>
                <div  className="Form">
                    <form onSubmit={handleSubmit}>
                        <input
                            type="text"
                            name="name"
                            placeholder={collaborateData.form.namePlaceholder}
                            value={formData.name}
                            onChange={handleChange}
                            required
                        />
                        <input
                            type="email"
                            name="email"
                            placeholder={collaborateData.form.emailPlaceholder}
                            value={formData.email}
                            onChange={handleChange}
                            required
                        />
                        <button type="submit">{collaborateData.form.buttonText}</button>
                    </form>
                </div>
            </div>
        </div>
    );
}
