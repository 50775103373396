import GeospatialBG from "../../assets/ServicesImgs/GeospatialBG.jpg";
import DigitalPhotogrammetry from "../../assets/ServicesImgs/DigitalPhotogrammetry.png";
import Lidar from "../../assets/ServicesImgs/Lidar.jpg";
import BillOfMaterials from "../../assets/ServicesImgs/BillOfMaterials.svg";

const gimData = {
    padding: "8px 15%",
    pageName: "Geospatial",
    bgImage: GeospatialBG,
    ImageGradient: "linear-gradient(180deg,#d8d9dd 0%,rgba(223,226,231,0.8) 40%)",
    MainSection: {
        title: "Geospatial",
        para: "Geospatial services are a broad range of services that involve the collection, analysis, and visualization of geographic data. These services are essential for various industries and applications, enabling better decision-making and efficient management of resources. Here are some lines on geospatial services provided:",
    },
    LogoCards: [
        { imgSrc: DigitalPhotogrammetry, name: "Digital Photogrammetry" },
        { imgSrc: Lidar, name: "LiDAR Services" },
        { imgSrc: BillOfMaterials, name: "Ortho Photo" },
    ],
};

export default gimData;
