import React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardActionArea from "@mui/material/CardActionArea";
import IconButton from "@mui/material/IconButton";
import { ChevronRight } from "@mui/icons-material";

const StyledCard = styled(Card)(({ theme }) => ({
    position: "relative",
    borderRadius: "5px",
    overflow: "hidden",
    boxShadow: theme.shadows[4],
    transition: theme.transitions.create(["transform", "box-shadow"], {
        duration: theme.transitions.duration.standard,
    }),
    "&:hover": {
        transform: "scale(1.03)",
        boxShadow: theme.shadows[8],
    },
    [theme.breakpoints.down("sm")]: {
        height: 150,
    },
}));

const ImageOverlay = styled(Box)(({ theme }) => ({
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    background: "rgba(187, 204, 212, 0.873)", 
    color: theme.palette.text.primary,
    borderRadius: `0 0 ${theme.shape.borderRadius}px ${theme.shape.borderRadius}px`,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(0.75),
    boxSizing: "border-box",
    transition: theme.transitions.create("background-color"),
}));

const OverlayContent = styled(Box)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
}));

const ArrowButton = styled(IconButton)(({ theme }) => ({
    color: theme.palette.primary.main,
    backgroundColor: "rgba(90, 157, 188, 0.027)",
    "&:hover": {
        backgroundColor: theme.palette.primary.light,
    },
}));

const ComplexButton = ({ image }) => {
    const navigate = useNavigate();

    return (
        <Box sx={{ width: image.width }}>
            <div data-aos="zoom-in">
                <StyledCard>
                    <CardActionArea onClick={() => navigate(image.redirect)}>
                        <CardMedia
                            component="img"
                            height="200"
                            image={image.url}
                            alt={image.title}
                            sx={{
                                transition: "opacity 0.3s ease",
                                "&:hover": {
                                    opacity: 0.9,
                                },
                            }}
                        />
                    </CardActionArea>
                        <ImageOverlay onClick={() => navigate(image.redirect)}>
                            <OverlayContent>
                                <Typography
                                    component="span"
                                    variant="h6"
                                    color="textPrimary"
                                    sx={{
                                        flex: 1,
                                        textAlign: "left",
                                        fontWeight: 600,
                                        fontSize: "20px"
                                    }}
                                >
                                    {image.title}
                                </Typography>
                                <ArrowButton>
                                    <ChevronRight />
                                </ArrowButton>
                            </OverlayContent>
                        </ImageOverlay>
                </StyledCard>
            </div>
        </Box>
    );
};

ComplexButton.propTypes = {
    image: PropTypes.shape({
        url: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        width: PropTypes.string.isRequired,
        redirect: PropTypes.string.isRequired,
    }).isRequired,
};

export default ComplexButton;
