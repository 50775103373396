import React from "react";
import FullWidthBanner from "../../components/FullWidthBanner/FullWidthBanner.jsx";
import Navbar from "../../components/Navbar/Navbar.jsx";
import Footer from "../Home/Footer/Footer.jsx";
import MarginDivider from "../../components/MarginDivider.jsx";
import LogoCard from "../../components/Cards/LogoCard/LogoCard.jsx";
import TestimonialsBg from "../../assets/Testimonials/TestimonialsBg.png";
import "./ServicesTemplate.scss";
import { APP_NAME } from "../../constants.js";
import {menus} from "../../data/menus.js";
import AppLogo from "../../assets/AppLogo.png";

const ServicePage = ({ data }) => {
    return (
        <div className="ServicesTemplate">
            <Navbar appName={APP_NAME} logoSrc={AppLogo} menus={menus} tagline={"Digital Networks"} />
            <FullWidthBanner padding={data.padding} bgImage={data.bgImage} pageName={data.pageName} />
            <div className="MainSection" style={{ backgroundImage: `url(${TestimonialsBg})` }}>
                <h3 className="header_s">{data.MainSection.title}</h3>
                <p className="para_s">{data.MainSection.para}</p>
                <MarginDivider margin={"10vh"} />
                <div className="Container">
                    {data.LogoCards.map((card, index) => (
                        <LogoCard key={index} imgSrc={card.imgSrc} name={card.name} size="large" />
                    ))}
                </div>
                <MarginDivider margin={"10vh"} />
            </div>
            <Footer />
        </div>
    );
};

export default ServicePage;
