import HighLevelDesignBg from "../../assets/ServicesImgs/HighLevelDesignBg.png";
import RouteMap from "../../assets/ServicesImgs/RouteMap.svg";
import TelecomEquipment from "../../assets/ServicesImgs/TelecomEquipment.svg";
import BillOfMaterials from "../../assets/ServicesImgs/BillOfMaterials.svg";
import Permits from "../../assets/ServicesImgs/Permits.svg";
import Aerial from "../../assets/ServicesImgs/Aerial.svg";

const highLevelDesignData = {
    padding: "8px 15%",
    pageName: "High Level Design",
    bgImage: HighLevelDesignBg,
    MainSection: {
        title: "High Level Design",
        para: "Our high-level design meets clients specific design considerations and leveraging years of experience that saves cost and re usable for detailed designs. Our ability to fine tune HLD to create bill of materials to order materials for timely build completion.",
    },
    LogoCards: [
        { imgSrc: RouteMap, name: "High-level route map" },
        { imgSrc: TelecomEquipment, name: "All telecom equipment’s location planned" },
        { imgSrc: BillOfMaterials, name: "Bill of materials" },
        { imgSrc: Permits, name: "Permits considerations" },
        { imgSrc: Aerial, name: "Aerial Vs underground considerations" },
    ],
};

export default highLevelDesignData;
