import React from "react";
import "./CultureCard.scss";
import { Typography } from "@mui/material";

const CultureCard = ({ icon: Icon, title, description }) => {
    return (
        <div data-aos="flip-down" className="CultureCard">
            <Icon className="icon" />
            <div className="textContainer">
                <Typography variant="h5" component="h2" className="title">
                    {title}
                </Typography>
                <Typography variant="body1" component="p" className="description">
                    {description}
                </Typography>
            </div>
        </div>
    );
};

export default CultureCard;
