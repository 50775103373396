import React, { useState } from "react";
import "./ContactUs.scss";
import Navbar from "../../components/Navbar/Navbar.jsx";
import { menus } from "../../data/menus.js";
import AppLogo from "../../assets/AppLogo.png";
import ContactUsBg from "../../assets/ContactUsBg.jpg";
import FullWidthBanner from "../../components/FullWidthBanner/FullWidthBanner.jsx";
import Footer from "../Home/Footer/Footer.jsx";
import { APP_NAME } from "../../constants.js";
import { contactInfo } from "../../data/contact-us/contactInfo.js";
import { COMPANY_EMAIL } from "../../constants.js";

const ContactUs = () => {
    const [formData, setFormData] = useState({ name: "", companyName: "", email: "", phone: "", message: "" });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const subject = encodeURIComponent('New Contact Us Form Submission');
        const body = encodeURIComponent(
            `Hello,\n\n` +
            `You have received a new message through the website.\n\n` +
            `Details:\n` +
            `----------------------------------\n` +
            `Name: ${formData.name}\n` +
            `Company Name: ${formData.companyName}\n` +
            `Email: ${formData.email}\n` +
            `Phone: ${formData.phone}\n` +
            `Message:\n` +
            `${formData.message}\n` +
            `----------------------------------\n\n` +
            `Please get in touch with the requester as soon as possible.\n\n` +
            `Thank you,\n` +
            `Your Website Team`
        );
        const mailtoLink = `mailto:${COMPANY_EMAIL}?subject=${subject}&body=${body}`;
        window.location.href = mailtoLink;
    };

    return (
        <div className="ContactUs">
            <Navbar appName={APP_NAME} logoSrc={AppLogo} menus={menus} tagline={"Digital Networks"} />
            <FullWidthBanner padding={"8px 15%"} bgImage={ContactUsBg} pageName={"Contact Us"} />
            <div className="ContactBox">
                <div data-aos="flip-left" className="SendMessageBox">
                    <h1>Leave us a Message</h1>
                    <div className="Form">
                        <form onSubmit={handleSubmit}>
                            <input type="text" name="name" placeholder="Name" value={formData.name} onChange={handleChange} required />
                            <input type="text" name="companyName" placeholder="Company Name" value={formData.companyName} onChange={handleChange} required />
                            <input type="email" name="email" placeholder="Email" value={formData.email} onChange={handleChange} required />
                            <input type="tel" name="phone" placeholder="Phone" value={formData.phone} onChange={handleChange} required />
                            <textarea name="message" placeholder="Tell us about your project" value={formData.message} onChange={handleChange} required />
                            <button type="submit">Send Message</button>
                        </form>
                    </div>
                </div>
                <div data-aos="flip-right" className="CompanyContactsBox">
                    {contactInfo.map((info, index) => (
                        <div className="InfoBox" key={index}>
                            {info.icon}
                            <p>{info.text}</p>
                        </div>
                    ))}
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default ContactUs;
