export const menus = [
    { menu: "Home", menuItems: [], redirect: "/" },
    {
        menu: "Services",
        menuItems: [
            {
                menu: "Telecom Planning & Design",
                redirect: "/engineering-design",
            },
            {
                menu: "Permitting",
                redirect: "/permitting",
            },
            {
                menu: "Traffic Control Plans",
                redirect: "/traffic-control-plans",
            },
            {
                menu: "Geospatial",
                redirect: "/geospatial",
            },
            {
                menu: "Pole Loading",
                redirect: "/pole-loading",
            },
            {
                menu: "BIM",
                redirect: "/bim",
            },
            {
                menu: "Feasibility study & Cost Analysis",
                redirect: "/feasibility-study-cost-analysis",
            },
            {
                menu: "Fielding",
                redirect: "/fielding",
            },
            {
                menu: "High level Design",
                redirect: "/high-level-design",
            },
            {
                menu: "Mechanical CAD",
                redirect: "/mechanical-cad",
            },
            {
                menu: "Civil and Architectural CAD",
                redirect: "/civil-architectural-cad",
            },
        ],
        redirect: undefined,
    },
    { menu: "About Us", menuItems: [], redirect: "/about-us" },
    { menu: "Careers", menuItems: [], redirect: "/careers" },
    { menu: "Contact Us", menuItems: [], redirect: "/contact-us" },
];
