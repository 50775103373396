import EngAndDesignBg from "../../assets/ServicesImgs/EngAndDesignBgV2.png";
import CADDrafting from "../../assets/ServicesImgs/CADDrafting.svg";
import WireLineDesign from "../../assets/ServicesImgs/WireLineDesign.svg";
import BusinessCellSite from "../../assets/ServicesImgs/BusinessCellSite.svg";
import IndexFiber from "../../assets/ServicesImgs/IndexFiber.svg";

const engAndDesignData = {
    padding: "8px 15%",
    pageName: "Telecom Planning & Design",
    bgImage: EngAndDesignBg,
    MainSection: {
        title: "Telecom Planning & Design",
        para: "We offer comprehensive telecom planning and design services, including FTTH, ISP, and OSP network design, such as GPON and FTTX networks. Our expertise spans from backbone and distribution planning to detailed documentation, splicing details, and GIS updates. Our global delivery model ensures optimized designs and faster rollouts. We also handle all stages of wireless network installations, including structural and zoning drawings, site builds, and tower upgrades.",
    },
    LogoCards: [
        { imgSrc: WireLineDesign, name: "Wireline and Wireless design" },
        { imgSrc: CADDrafting, name: "CAD Drafting: Construction & Permit Prints" },
        { imgSrc: BusinessCellSite, name: "Fiber to the home, business cell site" },
        { imgSrc: IndexFiber, name: "Indexed fiber, split node, distributed hub designs" },
    ],
};

export default engAndDesignData;
