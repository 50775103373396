import AutoScroller from "../../../components/AutoScroller/AutoScroller.jsx";
import MarginDivider from "../../../components/MarginDivider.jsx";
import AutoCad from "../../../assets/ExpertiseLogos/Cad.jpg";
import CalcPro from "../../../assets/ExpertiseLogos/Ocalc.jpg";
import Esri from "../../../assets/ExpertiseLogos/BIM.png";
import GIS from "../../../assets/ExpertiseLogos/3GIS.jpg";
import Microstation from "../../../assets/ExpertiseLogos/Microstation.jpg";
import Pro_Engineer from "../../../assets/ExpertiseLogos/Pro_Engineer.jpg";
import SNI from "../../../assets/ExpertiseLogos/SNI.jpg";
import Vault from "../../../assets/ExpertiseLogos/Vault.jpg";
import Auto_Desk from "../../../assets/ExpertiseLogos/Auto_Desk.jpg";
import earth_pro from "../../../assets/ExpertiseLogos/earth_pro.jpg";
import WindChill from "../../../assets/ExpertiseLogos/WindChill.jpg";
import Solidworks from "../../../assets/ExpertiseLogos/Solidworks.jpg";
import ArcGIS from "../../../assets/ExpertiseLogos/arc_gis.jpg";
import BIM from "../../../assets/ExpertiseLogos/BIM.png";
import QGIS from "../../../assets/ExpertiseLogos/Qgis.jpg";
import Iqgeo from "../../../assets/ExpertiseLogos/Iqgeo.png";
import RapidPlan from "../../../assets/ExpertiseLogos/Rapid plan.jpg";
import "./ExpertiseSection.scss";

export default function ExpertiseSection() {
    return (
        <div className="ExpertiseSection">
            <h3 data-aos="flip-right" className="header">Our area of expertise</h3> 
            <MarginDivider margin={"60px"} />
            <AutoScroller gap={45} speed={20}>
                <img src={Auto_Desk} alt="Logo" className="Expertise__image" />
                <img src={BIM} alt="Logo" className="Expertise__image" />
                <img src={ArcGIS} alt="Logo" className="Expertise__image" />
                <img src={QGIS} alt="Logo" className="Expertise__image" />
                <img src={GIS} alt="Logo" className="Expertise__image" />
                <img src={Microstation} alt="Logo" className="Expertise__image" />
                <img src={Iqgeo} alt="Logo" className="Expertise__image" />
                <img src={CalcPro} alt="Logo" className="Expertise__image" />
                <img src={RapidPlan} alt="Logo" className="Expertise__image" />
                <img src={SNI} alt="Logo" className="Expertise__image" />
                <img src={Pro_Engineer} alt="Logo" className="Expertise__image" />
                <img src={Solidworks} alt="Logo" className="Expertise__image" />
                <img src={AutoCad} alt="Logo" className="Expertise__image" />
                <img src={Esri} alt="Logo" className="Expertise__image" />
                <img src={Vault} alt="Logo" className="Expertise__image" />
                <img src={WindChill} alt="Logo" className="Expertise__image" />
                <img src={earth_pro} alt="Logo" className="Expertise__image" />
            </AutoScroller>
        </div>
    );
}