import React from "react";
import "./LogoCard.scss";

const LogoCard = ({ imgSrc, name, size = "small" }) => {
    return (
        <div data-aos="flip-down" className={`LogoCard ${size}`}>
            <img src={imgSrc} alt={name} className="LogoCard-image" />
            <div className="LogoCard-name">{name}</div>
        </div>
    );
};

export default LogoCard;
