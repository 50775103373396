import { RouterProvider } from "react-router-dom";
import React from "react";
import AOS from "aos";
import router from "./router.js";
import "aos/dist/aos.css";
import "./App.scss"


function App() {
	React.useEffect(() => {
        AOS.init();
    }, []);
    return (
		<RouterProvider router={router} />
	);
}

export default App;
