import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { networkData } from "../home/networkData.js";

export const journeyData = {
    header: "Our Journey",
    aboutUs: {
        title: "About Us",
        description: "Clearstream is a technology-driven engineering company founded by experienced professionals in engineering and business consulting. We offer services such as:",
        services: [
            { text: "Telecommunications Planning & Designs", icon: CheckCircleIcon },
            { text: "OSP/ISP engineering", icon: CheckCircleIcon },
            { text: "GIS mapping", icon: CheckCircleIcon },
            { text: "Traffic control plans", icon: CheckCircleIcon },
            { text: "Pole Loading Analysis & Fielding", icon: CheckCircleIcon },
            { text: "Electrical and mechanical drawings", icon: CheckCircleIcon },
            { text: "Business consulting support", icon: CheckCircleIcon },
        ],
    },
    strengths: {
        title: "Our Strengths",
        description: "We believe that teamwork is crucial to our success. Our strengths include:",
        points: [
            { text: "Technical expertise in integrating various technologies", icon: CheckCircleIcon },
            { text: "Commitment to quality and timely delivery", icon: CheckCircleIcon },
            { text: "Ensuring on-time delivery, every time", icon: CheckCircleIcon },
            { text: "Strong exposure to various engineering standards", icon: CheckCircleIcon },
            { text: "Outstanding quality to meet client benchmarks", icon: CheckCircleIcon },
            { text: "24/7 customer support", icon: CheckCircleIcon },
            { text: "Approach based on the client budget and timeline", icon: CheckCircleIcon },
        ],
    },
    infoCards: [
        ...networkData.cards
    ],
};
