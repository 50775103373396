import React from "react";
import "./QuotationCard.scss";

export default function QuotationCard({ quote, author, title }) {
    return (
        <div className="QuotationCard">
            <div className="QuotationCard-quoteSymbol">“</div>
            <div className="QuotationCard-content">
                <div className="QuotationCard-quote">
                    {quote}
                </div>
                <div className="QuotationCard-author">
                    <strong>{author}</strong><br />
                    {title}
                </div>
            </div>
        </div>
    );
}
