import MechanicalCadBg from "../../assets/ServicesImgs/MechanicalCadBg.jpg";
import ReverseEngineering from "../../assets/ServicesImgs/ReverseEngineering.png";
import ThreeDModeling from "../../assets/ServicesImgs/3DModeling.png";
import Drafting from "../../assets/ServicesImgs/Drafting.png";
import DataConversion from "../../assets/ServicesImgs/DataConversion.png";
import DataMigration from "../../assets/ServicesImgs/DataMigration.png";

const mechanicalCadData = {
    padding: "8px 15%",
    pageName: "Mechanical CAD",
    bgImage: MechanicalCadBg,
    MainSection: {
        title: "Mechanical CAD",
        para: "Clearstream offers mechanical engineering design, drafting, and modeling services to a wide range of companies across various industries, including automotive, aerospace, oil and gas, and pharmaceuticals. They cater to both start-ups and established businesses and use popular tools such as CATIA, ProE, Unigraphics, Inventor, SolidWorks, Solid Edge, and 3DVIA to provide comprehensive engineering, manufacturing, and production solutions.",
    },
    LogoCards: [
        { imgSrc: ReverseEngineering, name: "Reverse Engineering" },
        { imgSrc: ThreeDModeling, name: "3D Modeling" },
        { imgSrc: Drafting, name: "Drafting" },
        { imgSrc: DataConversion, name: "Data Conversion" },
        { imgSrc: DataMigration, name: "Data Migration" },
    ],
};

export default mechanicalCadData;
