import { createBrowserRouter } from "react-router-dom";
import Home from "./pages/Home/Home.jsx";
import AboutUs from "./pages/AboutUs/AboutUs.jsx";
import Careers from "./pages/Careers/Careers.jsx";
import ContactUs from "./pages/ContactUs/ContactUs.jsx";
import StudyAndCostAnalysis from "./pages/Services/StudyAndCostAnalysis.jsx";
import EngAndDesign from "./pages/Services/EngAndDesign.jsx";
import Fielding from "./pages/Services/Fielding.jsx";
import HighLevelDesign from "./pages/Services/HighLevelDesign.jsx";
import Geospatial from "./pages/Services/Geospatial.jsx";
import MechanicalCad from "./pages/Services/MechanicalCad.jsx";
import CivilAndArchCad from "./pages/Services/CivilAndArchCad.jsx";
import BIM from "./pages/Services/BIM.jsx";
import Permitting from "./pages/Services/Permitting.jsx";
import TrafficControlPlans from "./pages/Services/TrafficControlPlans.jsx";
import PoleLoading from "./pages/Services/PoleLoading.jsx";

const router = createBrowserRouter([
    {
        path: "/",
        element: <Home />
    },
    {
        path: "/about-us",
        element: <AboutUs />
    },
    {
        path: "/careers",
        element: <Careers />
    },
    {
        path: "/contact-us",
        element: <ContactUs />
    },
    {
        path: "/feasibility-study-cost-analysis",
        element: <StudyAndCostAnalysis />
    },
    {
        path: "/engineering-design",
        element: <EngAndDesign />
    },
    {
        path: "/fielding",
        element: <Fielding />
    },
    {
        path: "/high-level-design",
        element: <HighLevelDesign />
    },
    {
        path: "/geospatial",
        element: <Geospatial />
    },
    {
        path: "/mechanical-cad",
        element: <MechanicalCad />
    },
    {
        path: "/civil-architectural-cad",
        element: <CivilAndArchCad />
    },
    {
        path: "/bim",
        element: <BIM />
    },
    {
        path: "/permitting",
        element: <Permitting />
    },
    {
        path: "/traffic-control-plans",
        element: <TrafficControlPlans />
    },
    {
        path: "/pole-loading",
        element: <PoleLoading />
    }
]);

export default router;