import TrafficBg from "../../assets/ServicesImgs/TrafficBg.jpg";
import TrafficControlPlansImg from "../../assets/ServicesImgs/TrafficControlPlans.svg";
import EfficientTrafficFlow from "../../assets/ServicesImgs/EfficientTrafficFlow.svg";
import TrafficControlPackages from "../../assets/ServicesImgs/TrafficControlPackages.svg";
import SubmitPermit from "../../assets/ServicesImgs/SubmitPermit.svg";
import RoadUsers from "../../assets/ServicesImgs/RoadUsers.svg";
import WorkZoneDetails from "../../assets/ServicesImgs/WorkZoneDetails.svg";

const trafficControlPlansData = {
    padding: "8px 15%",
    pageName: "Traffic Control Plans",
    bgImage: TrafficBg,
    ImageGradient: "linear-gradient(180deg,#d8d9dd 0%,rgba(223,226,231,0.8) 40%)",
    MainSection: {
        title: "Traffic Control Plans",
        para: "Clearstream traffic engineering services deploys cutting-edge technology to design safe, efficient, and dependable traffic control plans. Whether your project requires minor traffic intrusion, or multiple lane closures, we’ll make sure things keep moving safely so you can focus on getting the work done.",
    },
    LogoCards: [
        { imgSrc: TrafficControlPlansImg, name: "Traffic control plans according to area standards" },
        { imgSrc: TrafficControlPackages, name: "Traffic control packages" },
        { imgSrc: EfficientTrafficFlow, name: "Efficient Traffic Flow" },
        { imgSrc: SubmitPermit, name: "Enhanced Safety" },
        { imgSrc: RoadUsers, name: "Minimize inconvenience for road users" },
        { imgSrc: WorkZoneDetails, name: "Provide through work zone details" },
    ],
};

export default trafficControlPlansData;
