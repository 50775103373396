import Navbar from "../../components/Navbar/Navbar.jsx";
import { menus } from "../../data";
import AppLogo from "../../assets/AppLogo.png";
import FullWidthBanner from "../../components/FullWidthBanner/FullWidthBanner.jsx";
import JourneySection from "./JourneySection/JourneySection.jsx";
import VisionSection from "./VisionSection/VisionSection.jsx";
import AboutBg from "../../assets/AboutUs.jpg";
import Footer from "../Home/Footer/Footer.jsx";
import { APP_NAME } from "../../constants.js";
import TeamSection from "./TeamSection/TeamSection.jsx";

export default function AboutUs() {
    return (
        <div className="AboutUs">
            <Navbar appName={APP_NAME} logoSrc={AppLogo} menus={menus} tagline={"Digital Networks"} />
            <FullWidthBanner
                padding={"8px 15%"}
                bgImage={AboutBg}
                pageName={"About Us"}
            />
            <JourneySection /> 
            <VisionSection />
            <TeamSection />
            <Footer />
        </div>
    );
}
