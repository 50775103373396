import React, { useEffect, useState } from "react";
import { Link } from "react-scroll";
import "./IntroSection.scss";
import DashboardIntro1 from "../../../assets/IntroDahsboardUi/Dashboard1.png";
import DashboardIntro2 from "../../../assets/IntroDahsboardUi/Dashboard2.png";
import DashboardIntro3 from "../../../assets/IntroDahsboardUi/Dashboard3.jpg";
import DashboardIntro4 from "../../../assets/IntroDahsboardUi/Dashboard4.png";
import MobileDasboardUI from "../../../assets/IntroDahsboardUi/MobileDasboardUI.jpg";
import { introData } from "../../../data/home/introData.js";
import "aos/dist/aos.css";

function IntroSection() {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isSmallScreen, setIsSmallScreen] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth <= 768);
        };
        handleResize(); // Check screen size on mount
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % 4);
        }, 3000);
        return () => clearInterval(interval);
    }, []);

    return (
        <div
            className="IntroSection"
            style={{
                backgroundImage: isSmallScreen
                    ? `url(${MobileDasboardUI})`
                    : "none",
            }}
        >
            <div data-aos="fade-right" className="IntroSection-content">
                <h1 className={true ? "live-write" : ""}>
                    {introData.title}
                </h1>
                <h2>{introData.subtitle}</h2>
                <p>{introData.description}</p>
                <Link to={introData.buttonTarget} smooth={true} duration={500}>
                    <button className="cta-button">
                        {introData.buttonText}
                    </button>
                </Link>
            </div>
            {!isSmallScreen && (
                <div data-aos="fade-left" className="IntroSection-carousel">
                    {[DashboardIntro1, DashboardIntro2, DashboardIntro3, DashboardIntro4].map(
                        (image, index) => (
                            <img
                                key={index}
                                src={image}
                                alt={`Dashboard ${index + 1}`}
                                className={`carousel-image ${
                                    index === currentIndex ? "active" : ""
                                }`}
                            />
                        )
                    )}
                    <div className="navigation-dots">
                        {[0, 1, 2, 3].map((_, index) => (
                            <span
                                key={index}
                                className={`dot ${
                                    index === currentIndex ? "active" : ""
                                }`}
                                onClick={() => setCurrentIndex(index)}
                            ></span>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
}

export default IntroSection;
