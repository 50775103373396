import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";

const ActionAreaCard = ({ image, title, description }) => {
    return (
        <div data-aos="zoom-in" className="action-area-card">
            <Card sx={{ maxWidth: 300, height: "100%" }}>
                <CardActionArea>
                    <CardMedia style={{ height: "300px" }} component="img" image={image} alt={title} />
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                            {title}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            {description}
                        </Typography>
                    </CardContent>
                </CardActionArea>
            </Card>
        </div>
    );
};

export default ActionAreaCard;
